<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张磊（zhanglei1204@jd.com）
 * @Date: 2021-05-07 18:03:04
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-22 23:36:34
 * @FilePath: /官网迭代/src/components/course/teacherInfo.vue
-->
<template>
  <div class="teacherInfo_component">
    <div class="block" v-if="$store.state.userInfo.teacherId">
      <div class="first_title">
        <img :src="constant.URL + $store.state.teacherInfo.photo" alt="" />
        <div class="title">
          {{ $store.state.teacherInfo.name }}<br />
          {{ $store.state.schoolInfo.atteSchool.schoolName }}
        </div>
      </div>
      <div class="item_title">等级:{{ $store.state.teacherInfo.level }}</div>
      <div class="item_title">
        积分:{{ $store.state.teacherInfo.integral }}分
      </div>
      <div class="item_title">
        备课:{{ $store.state.teacherInfo.preparesLessons }}节
      </div>
    </div>
    <div class="block" v-if="$store.state.userInfo.teacherId">
      <div class="first_title">我的班级:</div>
      <div class="item_title" v-for="(item, index) in list" :key="index">
        {{ `${handleType(item.grade.type)}-${item.grade.name}` }}<br />
        幼儿{{ item.stuCount || 0 }}人
      </div>
    </div>
    <div class="code">
      <img src="../../assets/img/lesson/wx.png" alt="" />
      <div class="title">
        关注Prefootball公众号<br />
        解锁更多惊喜
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "teacherInfoComponent",
  components: {},
  data() {
    return {
      list: [],
    };
  },
  created() {},
  mounted() {
    this.getClassList();
  },
  methods: {
    getClassList() {
      let data = {
        teacherId: this.$store.state.userInfo.teacherId,
      };
      if (this.$store.state.userInfo.teacherId) {
        this.api.school.findGradeListOfTid(data).then((res) => {
          this.list = res.data;
        });
      }
    },
    handleType(type) {
      let str = "";
      if (type == 0) {
        str = "大班";
      } else if (type == 1) {
        str = "中班";
      } else if (type == 2) {
        str = "小班";
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.teacherInfo_component {
  .code {
    width: 266px;
    height: 250px;
    background: #cc1a30;
    margin-bottom: 100px;
    font-size: 16px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
    text-align: center;
    img {
      width: 119px;
      height: 119px;
      border: 2px solid #ffffff;
      margin-top: 37px;
      margin-bottom: 23px;
    }
  }
  .block {
    box-sizing: border-box;
    margin-bottom: 13px !important;
    font-family: Alibaba PuHuiTi;
    font-size: 31px !important;

    .first_title {
      width: 266px;
      min-height: 84px;
      border-top-right-radius: 45px;
      background: #cc1a30;
      font-size: 26px;
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
      color: #ffffff;
      line-height: 44px;
      @include flex();
      img {
        width: 51px;
        height: 51px;
        background: #ffffff;
        border-radius: 50%;
        margin: 0 5px 0 11px;
      }
    }

    .item_title {
      width: 266px;
      padding-left: 50px;
      box-sizing: border-box;
      min-height: 69px;
      background: #f8f5f5;
      font-size: 24px;
      font-family: Alibaba PuHuiTi;
      font-weight: 500;
      color: #666666;
      line-height: 26px;
      margin-bottom: 7px;
      @include flex(row, flex-start, center);
    }

    .active {
      color: #cc1a30;
      position: relative;
    }

    .active::after {
      content: "";
      position: absolute;
      top: 20px;
      right: 26px;
      width: 10px;
      height: 29px;
      @include bg("../../assets/img/lesson/leftsideactive.png");
    }
  }
}
</style>
