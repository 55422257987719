<template>
  <div class="category_page">
    <div class="tabs">
      <div class="block" @click="toCourseList(experience)">
        <img :src="constant.URL + experience.course.image" alt="" />
        <div class="block_bottom">
          <div class="title">{{ experience.course.title }}</div>
          <div class="text">
            {{ experience.course.title }}<br />共{{ experience.courseCount }}节
          </div>
        </div>
      </div>
      <div class="block_2" @click="toCourseList(pay)">
        <img :src="constant.URL + pay.course.image" alt="" />
        <div class="block_bottom">
          <div class="title">{{ pay.course.title }}</div>
          <div class="text">{{ pay.course.title }}<br />共{{ pay.courseCount }}节</div>
        </div>
      </div>
    </div>
    <div class="teacher_block" v-if="$store.state.userInfo.teacherId">
      <evaluate />
    </div>
  </div>
</template>

<script>
import evaluate from "@/components/course/evaluate_component.vue";
export default {
  name: "category",
  components: {
    evaluate,
  },
  data() {
    return {
      experience: {course:{image:''}},
      pay: {course:{image:''}},
    };
  },
  created() {},
  async mounted() {
    //体验课
    let res = await this.api.course.findCourseInfoByCourseId({
      courseId: 2,
      isPremium: 0,
    });
    this.experience = res.data;
    let result = await this.api.course.findCourseInfoByCourseId({
      courseId: 1,
      isPremium: 1,
    });
    this.pay = result.data;
  },
  methods: {
    toCourseList(val) {
      this.$router.push({
        path: "/courseList",
        query: {
          courseId: val.id,
          isPremium: val.course.ispremium
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.category_page {
  width: 100%;
  .tabs {
    width: 90%;
    margin: 0 auto;
    @include flex(row, space-around, center);
    .block,
    .block_2 {
      width: 45%;
      height: 1000px;
      box-sizing: border-box;
      @include flex(column, center, center);
      cursor: pointer;
      margin: 200px 0;
      img {
        width: 100%;
        height: 445px;
      }
      .block_bottom {
        width: 100%;
        height: 555px;
        background: #228ded;
        box-sizing: border-box;
        padding: 150px 90px;
        text-align: center;
        .title {
          background: #fff;
          height: 90px;
          font-size: 60px;
          line-height: 90px;
          letter-spacing: 10px;
          color: #228ded;
          border-radius: 50px;
        }
        .text {
          font-size: 38px;
          line-height: 50px;
          letter-spacing: 8px;
          color: #ffffff;
          margin-top: 50px;
        }
      }
    }
    .block_2 {
      .block_bottom {
        background: #cb1b31;
        .title {
          color: #cb1b31;
        }
      }
    }
    .title:hover {
      opacity: 0.8;
    }
  }
}
</style>
