

<template>
  <div class="evaluate_component">
    <teacherInfo />
    <div class="right">
      <div class="top">
        <!-- <div
          class="tab_list_one tab"
          :class="{ tab_list_active: tabListActive === 'one' }"
          @click="tabListActive = 'one'"
        >
          待评分列表
        </div> -->
        <div
          class="tab_list_two tab"
          :class="{ tab_list_active: tabListActive === 'two' }"
          @click="tabListActive = 'two'"
        >
          上课进度
        </div>
      </div>
      <div class="tab_list_one_block" v-show="tabListActive === 'one'">
        <div class="custTab">
          <table class="layui-table" lay-skin="nob">
            <thead>
              <tr>
                <th>班级</th>
                <th>班级课程</th>
                <th>时间</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in list1" :key="index">
                <td>{{ handleType(item.gradeType) }}</td>
                <td>
                  {{ item.gradeClassName }}
                </td>
                <td>{{ common.dateFormat(item.updatetime * 1000) }}</td>
                <td class="text-center">
                  <div
                    class="btn btn-blue tab"
                    @click="
                      $router.push({
                        path: '/evaluate',
                        query: { id: item.id, videoId: item.videoId },
                      })
                    "
                  >
                    教师评分
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <comPagination
          :params="params1"
          @changePage="changePage1"
        ></comPagination>
      </div>
      <div class="tab_list_two_block" v-show="tabListActive === 'two'">
        <div class="layui-tab-item layui-show">
          <ul class="progress" v-for="(item, index) in list2" :key="index">
            <li>
              <div class="flex-1">
                <div class="prog">
                  <div>
                    {{ `${handleType(item.grade.type)} · ${item.grade.name}` }}
                  </div>
                  <div>
                    <span class="on">{{ item.passClassCount }}</span
                    >/16节
                  </div>
                </div>
                <div class="state">
                  <div
                    class="s"
                    :style="{ width: `${(item.passClassCount / 16) * 100}%` }"
                  ></div>
                </div>
              </div>
              <div
                class="toclass tab"
                @click="
                  $router.push({ path: '/attendClass', query: { ...item.grade } })
                "
              >
                立即上课
              </div>
            </li>
          </ul>
        </div>
      </div>
      <comPagination
        :params="params2"
        @changePage="changePage2"
      ></comPagination>
    </div>
  </div>
</template>

<script>
import teacherInfo from "@/components/course/teacherInfo.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "evaluateComponent",
  components: {
    teacherInfo,
    comPagination,
  },
  data() {
    return {
      tabIndex: 0,
      tabListActive: "two",
      params1: {
        // 评分分页
        page: 0,
        size: 10,
        total: 0,
      },
      params2: {
        // 上课进度分页
        page: 0,
        size: 10,
        total: 0,
      },
      list1: [],
      list2: [],
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getClassList();
  },
  methods: {
    getList() {
      this.api.pes
        .findWaitSore(this.params1, {
          userId: this.$store.state.userInfo.userId,
        })
        .then((res) => {
          this.list1 = res.data.list;
          this.params1.total = res.data.total;
        });
    },
    // 老师班级列表
    getClassList() {
      let data = {
        teacherId: this.$store.state.userInfo.teacherId,
      };
      this.api.admin.findGradesOfTeacherId(data).then((res) => {
        this.list2 = res.data;
      });
    },
    changePage1(val) {
      this.params1.page = val;
      this.getList();
    },
    changePage2(val) {
      this.params2.page = val;
      this.getClassList();
    },
    handleType(type) {
      let str = "";
      if (type == 0) {
        str = "大班";
      } else if (type == 1) {
        str = "中班";
      } else if (type == 2) {
        str = "小班";
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.evaluate_component {
  @include flex(row, space-between, flex-start);
  .right {
    flex: 1;
    margin-bottom: 100px;
    box-sizing: border-box;
    padding: 0 60px;
    .top {
      margin-bottom: 20px;
      @include flex(row, flex-start, center);
      .tab_list_one {
        line-height: 80px;
        font-size: 24px;
        font-weight: 700;
        background: #e58d98;
        color: #fff;
        padding-left: 120px;
        padding-right: 150px;
        border-top-right-radius: 75px;
        z-index: 333;
      }
      .tab_list_two {
        line-height: 80px;
        font-size: 24px;
        font-weight: 700;
        background: #e58d98;
        color: #fff;
        // margin-left: -70px;
        padding-left: 120px;
        padding-right: 70px;
        z-index: 222;
        border-top-right-radius: 0.75rem;
      }
      .tab_list_active {
        background: #cb1b31;
      }
    }
    .tab_list_one_block {
      position: relative;
      margin-bottom: 40px;
      .layui-table {
        padding: 0;
        margin: 0;
        width: 100%;
        background-color: #fff;
        color: #666;
        border-collapse: collapse;
        border-spacing: 0;
        text-align: left;

        thead {
          position: relative;
          tr {
            background-color: #f2f2f2;
          }
          th {
            background: #cc1a30;
            line-height: 30px;
            font-weight: 700;
            color: #fff;
            font-size: 20px;
            padding: 15px;
          }
        }
        tbody {
          border-bottom: solid 3px #cc1a30;
          tr {
            border-bottom: solid 1px #cb1b31;
          }
          td {
            color: #3e3e3e;
            font-size: 18px;
            line-height: 30px;
            padding: 15px;
          }
          td:last-child {
            padding-right: 0;
          }
          .btn-blue {
            height: 20px;
            line-height: 20px;
            background: #228cec;
            color: #fff;
            display: inline-block;
            font-size: 20px;
            font-weight: bold;
            padding: 10px;
            float: right;
            margin-left: 12px;
          }
        }
      }
      .layui-table td,
      .layui-table th {
        border: none;
      }
    }
    .tab_list_two_block {
      font-size: 20px;
      line-height: 30px;
      color: #cb1b31;
      .progress {
        padding: 0 50px;
        li {
          display: flex;
          border-bottom: solid 2px #cb1b31;
          padding: 15px 0;
          display: flex;
          line-height: 35px;
          flex: 1;
          .flex-1 {
            display: flex;
            line-height: 35px;
            padding-right: 50px;
            flex: 1;
            .prog {
              width: 400px;
              padding-right: 100px;
              display: flex;
              div {
                padding-right: 50px;
              }
              .on {
                font-weight: 700;
              }
            }
            .state {
              width: 500px;
              height: 35px;
              background: #bcdcf9;
              border-radius: 25px;
              position: relative;
              .s {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                background: #228cec;
                border-radius: 25px;
              }
            }
          }
        }
        .toclass {
          width: 150px;
          background: #228cec;
          border-radius: 25px;
          text-align: center;
          color: #fff;
          line-height: 35px;
        }
      }
    }
  }
}
</style>

